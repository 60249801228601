.Toastify {
  flex: 0 1 auto !important;
}
.Toastify__toast-container {
  max-width: 480px;
  width: 100%;
}
.Toastify__toast {
  padding: 16px 32px 16px 16px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px #7c909b;
}
.Toastify__toast--success {
  color: #22af27;
  border-top: 4px solid #22af27;
}
.Toastify__toast--warning {
  color: #f3a712;
  border-top: 4px solid #f3a712;
}
.Toastify__toast--error {
  color: #f31212;
  border-top: 4px solid #f31212;
}
.Toastify__toast--info {
  color: #7c909b;
  border-top: 4px solid #7c909b;
}
.Toastify__toast-body {
  padding: 0;
}
.Toastify .close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
}
.Toastify__toast--success .close-button {
  color: color(other-8);
}
.Toastify__toast--warning .close-button {
  color: color(other-5);
}
.Toastify__toast--error .close-button {
  color: color(other-3);
}
.Toastify__toast--info .close-button {
  color: color(text-1);
}
.Toastify .type-icon {
  font-size: 24px;
}
