/*
 * Overrides
 */

.react-datepicker-search + .react-datepicker__tab-loop .react-datepicker-popper,
.react-datepicker-departure-date
  + .react-datepicker__tab-loop
  .react-datepicker-popper {
  z-index: 55;
}

.react-datepicker-search
  + .react-datepicker__tab-loop
  .react-datepicker-popper {
  top: 24px !important;
}

.react-datepicker__navigation-icon::before {
  border-color: white;
}

.react-datepicker__navigation:hover .react-datepicker__navigation-icon::before {
  border-color: #0a8ed6;
}

.react-datepicker {
  border: none;
  border-radius: 16px;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  border: none;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  background-color: #ed4054;
}

.react-datepicker__triangle {
  fill: #ed4054 !important;
  color: #ed4054 !important;
  stroke: #ed4054 !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker-time__header {
  color: white;
}

.react-datepicker-search input {
  padding-left: 32px;
  font-size: 18px;
  color: white;
  background-color: transparent;
  appearance: none;
}

.react-datepicker-search input::placeholder {
  color: #e1e1e5;
}

.react-datepicker-search input.text-white,
.react-datepicker-search input.text-white::placeholder {
  color: white;
}

.react-datepicker-search input.text-secondary,
.react-datepicker-search input.text-secondary::placeholder {
  color: #7c909b;
}

.react-datepicker-departure-date input {
  padding: 8px 16px;
  font-size: 16px;
  height: 56px;
  width: 100%;
  color: #3f4e61;
  border: 1px solid #bbbbc1;
  border-radius: 16px;
  appearance: none;
}

.react-datepicker__day--selected {
  background-color: #0a8ed6;
}

.react-datepicker__day:hover {
  background-color: rgba(10, 142, 214, 0.25);
}
