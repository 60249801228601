@import url('./styles/index.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700');

body {
  @apply text-primary;
}

.space-before::before,
.space-after::after,
.space-both:before,
.space-both:after {
  content: '\00a0';
}

.slick-slider {
  @apply w-full;
}

.required-label:after {
  content: '*';
  display: inline-block;
  font-size: smaller;
  @apply inline-block relative left-0.5 -top-0.5 text-error;
}

select:focus ~ label,
select:not([value='']):valid ~ label {
  @apply transform;
  @apply scale-75;
  @apply -translate-y-4;
}

select:focus ~ label {
  @apply text-black;
  @apply left-4;
}
