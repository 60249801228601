.terms-page h1 {
  margin: 0.67em 0;
  font-weight: bold;
  font-size: 32px;
}

.terms-page h2 {
  font-weight: bold;
  margin: 0.83em 0;
  font-size: 20px;
}

.terms-page h4 {
  font-weight: bold;
  margin: 1em 0;
  font-size: 16px;
}

.terms-page ul {
  margin: 1em 0;
  list-style-type: disc;
  padding-inline-start: 40px;
}

.terms-page ol {
  margin: 1em 0;
  list-style-type: decimal;
  padding-inline-start: 40px;
}

.terms-page li {
  display: list-item;
}

.terms-page p {
  margin: 1em 0;
  font-size: 16px;
}
